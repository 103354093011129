import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/SEO"
import Chat from "../components/Chat"
import { Provider } from "mobx-react"
import ChatStore from "../stores/chat.store"

const ManAndVan = () => (
    <section id="man-and-van">
        <h2>Man and Van</h2>
        <p>One of the basic, yet most sought after, highly reliable and efficient services that we offer here at London based <b>Man with Van</b> is the Man and Van Services. Because we know how difficult it can be for you to arrange for the pick up and transportation of various sorts of durable or fragile items ranging from small to medium sizes and coming in any shape, we are here to sort it all out for you.</p>
        <p>Whether you need quick and effective help with single items, furniture, E-bay or Ikea pick-ups and transportation or a&nbsp; light house or office removal is more what you have in mind, you can rest assured that our Man and Van Service is both affordable and convenient. Get in touch with us whenever you need a proficient <b>Man with a Van</b> within London and Greater London and we will be there to help you out at the shortest notice.</p>
        <p>We have the latest Van&nbsp;models for single items and personal effects. If you are a student moving house and you do not have any furniture items to remove, then this service is perfect for you. Not only is our <b>Man and Van Service</b> going to save you a lot of time and hassle, but money as well. All you need in order to move your stuff from one rented place to another is one of our Vans and a man to take care of everything for you.</p>
        <p>Furthermore, we can also help you with the <b>Packing, Unpacking, Cleaning </b>and even<b> Fixing</b> of both the old and new place if you are too busy studying or working. Here, at The Removal Man&nbsp;we like doing all the hard work for our clients in the safest and most reliable way possible because their satisfaction always comes first.</p>
        <p>If a light move of house or office including small to medium furniture or other similar belongings is what you need, then do not waste any more time and effort trying to figure out how to do it on your own. Our highly professional employees have years of experience in this field and are always glad to handle it with the greatest care and attention. For this type of Man and Van Service we will come with a smoothly-running <b>Bigger Van</b> straight to your door step and safely remove all your valuable possessions.</p>
        <p>The reputation of our company is extremely important for all of us, so you can rest assured that we will always be responsive to all your needs and wants. This is exactly why here at&nbsp;The Removal Man&nbsp;we are always ready to offer you a piece of advice and free quotes, no matter what your queries might be. If you do not know what vehicle or how many people you need to hire for the removal of your items, do not waste your time trying to figure out the right answer. Our professional removal co-ordinators and supervisors are here to assess your needs at any time, so give us a call or drop us an e-mail at your convenience.</p>
        <p>We always like to go the extra mile when it comes to the satisfaction of our clients, this is exactly why we think that standardized Man and Van Services are a thing of the past. Each and every one of our clients has specific removal needs, especially within the hectic city of London. So we will always be able to tailor our Man with a Van Service to the client’s taste. Get in touch with us today and everyone, from our soft-spoken receptionist to our specialist removal staff, will be more than glad to assist you and help you get done with the troubling experience of moving house or office as soon as possible.</p>
        <p>We have fully equiped vehicles and all our staff is well-trained and helpful so you can be at peace that we will carefully look after your belongings as if they were ours. We know how treasured&nbsp; any of your possessions is to you both from an emotional and financial point of view, so relax and start making future plans about your new home or office right away!</p>
    </section>
)

const Removal = () => (
    <section id="full-removal">
        <h2>Full Removal</h2>
        <p>Whether you are too busy working, studying or spending time with your family or you simply dread the incredible hassle moving house or office brings about, now that you have found us you do not have to worry about a thing. We have everything it takes to offer you a <b>stress-free, complete removal experience</b>. Our state of the art, roomy vehicles are ideal for transporting all your items fast and securely. At the same time, our <b>reliable, well-trained and experienced staff</b> can deal with your valuable and fragile possessions in the safest and most proficient way.</p>
        <p>If you are looking to pick-up or move small and medium items, you can always check our <a href="#man-and-van">Man and Van Services</a> section, which is also fit for the removal of studio flats, one bedroom and even small two bedroom flats and medium-sized offices, furniture included. On the other hand, if <a href="#full-removal">Full Removal Services</a> from and to larger properties is what you need, you can rest assured that our proficient team, vehicles and equipment can face any challenge, no matter how large or heavy! In conjunction with these services, we also offer you professional Packing, Unpacking and Cleaning Services to save you of all the related trouble. If you want to find out more in this regard, please check these separate services on our website at hand.</p>
        <p>In order to provide you with <b>the best Full Removal Services</b> at any time and at the shortest notice, we have acquired the latest and smoothest-running Box Van and Truck models where we can fit in tons of your belongings. Then, we will transport them safely to the appointed destination anywhere in London and Greater London. We also have an on the go insurance of up to £25,000, meaning that your items are safe with us from the very beginning to the very end of our treasured professional collaboration.</p>
        <p>The same as with our Man and Van Services, our Full Removal Services is specifically designed for both domestic and commercial needs of any sort. We take pride in being able to always provide for the specific needs of our clients, up to any extent. Here at Man with Van and Storage Solutions, we are up to date with the distinct needs and wants of the continually growing and changing removal market and we think that standardized services are outdated. This is exactly why we try to tailor our Man and Van and Full Removal Services to client’s taste.</p>
        <p>Furthermore, because the peace of mind of our clients always comes first, we offer <b>full insurance</b> of belongings that goes up to £25,000. In other words, all your possessions are in safe hands with our removal-specialized team, from the moment our trained staff pick up the first item until they drop off the last one. Our operators, supervisors and furniture experts have first-rate trade knowledge, expertise and specialized mechanical tools, so you do not have to worry about anything at all. Our people will always ensure that all your belongings are packed, stored and unpacked safely and without suffering any damage. Our specialists can also handle and reach any type of item, no matter how difficult it might be to access it.</p>
        <p>From the most basic to the most complex Man with a Van and Full Removal Services, we are here not only to take your order, but also to previously help you assess your exact needs. We adopt a <b>trustworthy, helpful, customer-oriented approach</b> so you can call us any time and our receptionist and specialists will be more than happy to offer you a free quote and more before you even decide to hire our services.</p>
        <p>Here at <b>Man with Van and Storage Solutions</b> we also practice an honest approach, so we will never recommend you to go for a Full Removal package when actually all you need is a Man with a Van. Because our reputation precedes us everywhere we might go, we also offer difficult-to-match after service when the situation requires. However, you can be sure that we will not leave before everything is set in place and we have proudly shaken your hand at the end of the provided service.</p>
    </section>
)

class ServicesPage extends React.Component {

    constructor(props) {
        super(props)
        this.stores = {
            chat: new ChatStore()
        }
    }

    render() {
        return (
            <Provider
                chat_store={this.stores.chat}
            >
                <Layout >
                    <SEO title="Home" />

                    <Chat />

                    <article className="post top-offset">
                        <h1>Our Services</h1>
                        <ManAndVan />
                        <Removal />
                    </article>


                </Layout>
            </Provider>
        )
    }
}

export default ServicesPage
